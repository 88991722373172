<template>
  <div>
    <job-quote-card v-for="quote in acceptedQuotes" :key="quote.id"
                    class="mb-3"
                    @quote-accepted="$emit('quote-accepted')"
                    :job="job" :quote="quote">
    </job-quote-card>
    <job-quote-card v-for="quote in otherQuotes" :key="quote.id"
                    class="mb-3"
                    @quote-accepted="$emit('quote-accepted')"
                    :job="job" :quote="quote">
    </job-quote-card>
  </div>
</template>

<script>
import JobQuoteCard from "./QuotesProvidedList/JobQuoteCard";

export default {
  name: "QuotesProvidedList",
  components: {JobQuoteCard},
  props: ['job'],
  computed: {
    acceptedQuotes() {
      let quotes = [];
      if (this.job.accepted_job_quote_id) {
        quotes.push(
            _.find(this.job.job_quotes, {id: this.job.accepted_job_quote_id})
        );
      }
      return quotes;
    },
    otherQuotes() {
      if (this.job.accepted_job_quote_id) {
        return _.filter(this.job.job_quotes, q => {
          return q.id !== this.job.accepted_job_quote_id;
        });
      }
      return this.job.job_quotes;
    }
  }
}
</script>

<style scoped>

</style>