<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">
          Job Details
        </h1>
      </div>
    </section>
    <!--- / Page Header -->

    <!-- Job Details -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">

        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Is Loading -->
        <div v-if="isLoading" class="text-center">
          <spinner></spinner>
        </div>
        <!-- / Is Loading -->

        <!-- Loaded -->
        <div v-if="!isLoading && job" class="row">
          <div class="col-lg-12 position-relative mb-5 mb-lg-0" style="z-index: 1025;">
            <div class="mb-2">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <h2 class="h3 mb-0">{{ job.title }}</h2>
                  <div class="mt-3 mb-4">

                    <!-- Is Rejected -->
                    <span v-if="isRejected && !isClient" class="badge bg-danger">Rejected</span>
                    <!-- / Is Rejected -->
                    <!-- Not Rejected Status Badge -->
                    <span v-if="!isRejected || isClient" class="badge bg-primary">{{
                        getStatusLabel(job.status)
                      }}</span>
                    <!-- / Not Rejected Status Badge -->

                  </div>
                </div>
                <div class="flex-shrink-1">
                  <button v-if="isProvider && job.status === 0"
                          @click="provideQuote" type="button" class="btn btn-primary">
                    <i class="fi-edit me-2"></i>
                    Provide Quote
                  </button>

                  <button v-if="isProvider && job.status === 1"
                          @click="closeJob"
                          type="button" class="btn btn-primary">
                    <i class="fi-check   me-2"></i>
                    Close Job
                  </button>
                </div>
              </div>
            </div>
            <!--            <ul class="list-unstyled fs-sm mb-4">-->
            <!--              <li class="mb-2"><a class="d-flex align-items-center text-decoration-none"-->
            <!--                                  href="job-board-employer-single.html"><i class="fi-external-link me-2"></i><span-->
            <!--                  class="text-decoration-underline">Zalo Tech Company</span></a></li>-->
            <!--              <li class="d-flex align-items-center mb-2"><i class="fi-map-pin text-muted me-2"></i><span>New York</span>-->
            <!--              </li>-->
            <!--              <li class="d-flex align-items-center mb-2"><i-->
            <!--                  class="fi-cash fs-base text-muted me-2"></i><span>$10,000</span></li>-->
            <!--              <li class="d-flex align-items-center mb-2"><i class="fi-phone text-muted me-2"></i><span class="me-2">Bessie Cooper, HR Manager</span><a-->
            <!--                  href="#">Show phone</a></li>-->
            <!--              <li class="d-flex align-items-center mb-2"><i class="fi-clock text-muted me-2"></i><span class="me-2">Full-time</span>-->
            <!--              </li>-->
            <!--            </ul>-->
            <!--            <hr class="mb-4">-->

            <!-- Details -->
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body">
                  <div v-if="!isClient">
                    <h3 class="h6 mb-1">Client:</h3>
                    <p>
                      {{ job.client_user_role ? job.client_user_role.name : '' }}
                      <span v-if="job.client_user_role && job.client_user_role.company_name">({{
                          job.client_user_role.company_name
                        }})</span>
                    </p>
                  </div>

                  <h3 class="h6 mb-1">Description:</h3>
                  <p :class="{'mb-0': !isJobClosed(job)}">
                    {{ job.description }}
                  </p>

                  <div v-if="isJobClosed(job)">
                    <h3 class="h6 mb-1">This job has been closed. The final bill is:</h3>
                    <p>
                      {{ formatCostInPence(job.job_closure.value) }}
                    </p>
                  </div>

                  <div v-if="isJobClosed(job)">
                    <h3 class="h6 mb-1">Job closure confirmed by client at:</h3>
                    <p class="mb-0">
                      <span v-if="job.job_closure.confirmed_by_client_at">
                        {{ displayTimestamp(job.job_closure.confirmed_by_client_at) }}
                      </span>
                      <span class="text-danger" v-else>Client hasn't confirmed yet</span>
                    </p>
                  </div>
                </div>
              </div>
              <!-- / Details -->
            </div>
            <!-- / Details -->

            <!-- Messaging -->
            <div class="col-lg-8 mt-5">
              <job-messaging v-if="job"
                             :job-id="job.id"></job-messaging>
            </div>
            <!-- / Messaging -->

            <!-- Client: Quotes provided -->
            <div class="col-lg-8">
              <h3 class="h6 mt-5">Quotes</h3>

              <quotes-provided-list
                  v-if="job.job_quotes && job.job_quotes.length"
                  :job="job"
                  @quote-accepted="fetch"
              ></quotes-provided-list>

              <no-results v-if="!job.job_quotes || job.job_quotes.length === 0" class="bg-tertiary">
                No quotes to show
              </no-results>
            </div>
            <!-- / Client: Quotes provided -->

          </div>

          <!--          <aside class="col-lg-4" style="margin-top: -6rem;">-->
          <!--            <div class="sticky-top" style="padding-top: 6rem;">-->
          <!--              <div class="card shadow-sm mb-3 mb-lg-0">-->
          <!--                <div class="card-body">-->
          <!--                  <h2 class="h3">Advisors</h2>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </aside>-->
        </div>
        <!-- / Loaded -->

      </div>
    </section>
    <!-- / Job Details -->

  </div>
</template>

<script>
import Spinner from "../../components/Spinner";
import Dates from "../../mixins/Dates";
import jobs from "../../api/jobs";
import Forms from "../../mixins/Forms";
import Breadcrumb from "../../components/Breadcrumb";
import Jobs from "../../mixins/Jobs";
import {mapGetters} from "vuex";
import QuotesProvidedList from "./Quotes/QuotesProvidedList";
import NoResults from "../../components/NoResults";
import Display from "../../mixins/Display";
import JobMessaging from "./Messaging/JobMessaging";

export default {
  name: "Show.vue",
  components: {QuotesProvidedList, Spinner, Breadcrumb, NoResults, JobMessaging},
  mixins: [Forms, Jobs, Display, Dates],
  data() {
    return {
      isLoading: false,
      job: null,
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "/jobs",
          label: "Jobs",
        },
        {
          href: "#",
          label: "Job Details",
          active: true,
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole',
      isProvider: 'isProvider',
      isAdvisor: 'isAdvisor',
      isClient: 'isClient'
    }),

    jobMembership() {
      return _.find(this.job.job_members, {user_role_id: this.userRole.id});
    },

    isRejected() {
      if (this.jobMembership) {
        return this.isJobRejected(this.job, this.jobMembership)
      }
      return false;
    },

    isAccepted() {
      if (this.jobMembership) {
        return this.isJobAccepted(this.job, this.jobMembership)
      }
      return false;
    },

    canAcceptOrReject() {
      return this.canAcceptOrRejectJob(this.job, this.jobMembership);
    }
  },
  watch: {},
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      let vm = this;
      vm.job = null;
      vm.isLoading = true;
      vm.serverErrors = [];
      jobs.find(this.$route.params.id).then(r => {
        vm.isLoading = false;
        vm.job = r.data;
        let jobTitleWithHashEscaped = vm.job.title.replaceAll('#', '%23');
        vm.$router.push('/messaging?initialJobId=' + vm.job.id + '&initialJobTitle=' + jobTitleWithHashEscaped);
      }).catch(e => {
        console.log(e);
        vm.setAndNotifyErrors(e, 'Error loading job');
        vm.$router.push('/jobs');
      });
    },

    provideQuote() {
      this.$router.push('/jobs/' + this.job.id + '/quotes/create');
    },

    closeJob() {
      this.$router.push('/jobs/' + this.job.id + '/close');
    }
  }
}
</script>

<style scoped>

</style>