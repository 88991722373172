<template>
  <div class="tab-selector px-3 py-3">
    <div class="left">
      <div class="description">{{ description }}</div>
    </div>
    <div class="right">

      <ul class="nav nav-tabs card-header-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link" :class="{'active': isTabSelected('activity')}" href="#" role="tab"
          @click.prevent="$emit('select-tab', 'activity')">
            Activity
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{'active': isTabSelected('files')}" href="#" role="tab"
             @click.prevent="$emit('select-tab', 'files')">
            Files
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{'active': isTabSelected('messaging')}" href="#" role="tab"
             @click.prevent="$emit('select-tab', 'messaging')">
            Messages
          </a>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
  name: "TabSelector",
  props: ['currentTab'],
  computed: {
    description() {
      if (this.currentTab === 'messaging') {
        return 'Messages'
      } else if (this.currentTab === 'files') {
        return 'Sent Files'
      } else if (this.currentTab === 'activity') {
        return 'Activity'
      }
      return this.currentTab
    }
  },
  methods: {
    isTabSelected(tab) {
      return this.currentTab === tab
    }
  }
}
</script>

<style scoped lang="scss">
.tab-selector {
  border-bottom: 1px solid #efecf3;
  border-left: 1px solid #efecf3;
  border-right: 1px solid #efecf3;
  display: flex;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;

    .description {
      font-weight: bold;
      color: #1f1b2d;
    }
  }

  .right {
    flex-shrink: 1;
  }
}
</style>