<template>
  <div class="conversation-files-wrapper">
    <div class="p-3 conversation-files">

      <!-- Loading -->
      <div v-if="isLoading" class="text-center py-5">
        <spinner></spinner>
      </div>
      <!-- / Loading -->

      <!-- No files -->
      <no-results v-if="!isLoading && files.length === 0">
        No files to show
      </no-results>
      <!-- / No files -->

      <!-- Files -->
      <div class="row">
        <div class="col-md-4" v-for="file in files">
          <div class="card bg-secondary">
            <div class="card-body text-center">
              <i class="fi fi-download-file file-card-icon"></i>
              <h5 class="card-title">{{ file.filename }}</h5>
              <p class="card-text fs-sm">
                Sent by: {{ file.sent_by_user_role.name }}
              </p>
              <a href="#" @click.prevent="downloadConversationFile(file, jobId)"
                 class="btn btn-sm btn-primary">Download</a>
            </div>
          </div>
        </div>
      </div>
      <!-- / Files -->

    </div>
  </div>
</template>

<script>
import conversations from "../../../../api/conversations";
import {useToast} from 'vue-toastification';

const toast = useToast();
import Spinner from "../../../../components/Spinner";
import NoResults from "../../../../components/NoResults";
import Conversations from "../../../../mixins/Conversations";

export default {
  name: "ConversationFiles",
  props: ['jobId', 'conversation'],
  components: {Spinner, NoResults},
  mixins: [Conversations],
  data() {
    return {
      isLoading: false,
      files: []
    }
  },
  created() {
    this.loadFiles();
  },
  methods: {
    loadFiles() {
      let vm = this;
      vm.isLoading = true;
      conversations.loadFiles(
          this.jobId,
          this.conversation.id
      ).then(r => {
        vm.files = r.data;
        vm.isLoading = false;
      }).catch(e => {
        console.log(e);
        // toast.error('Error loading files');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.conversation-files {
  border: 1px solid #efecf3;
  border-top: none;
  overflow-y: scroll;
  min-height: 400px;
  max-height: 400px;
  background: #fff;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  .file-card-icon {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

.conversation-files-wrapper {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 1px solid #efecf3;
  border-top: none;
  background: #f9f9f9;
}

</style>