<template>
  <div>
    <ul class="nav nav-tabs nav-fill" role="tablist" v-show="companies.length">
      <li class="nav-item me-2" v-for="(company, i) in companies" :key="i">
        <a class="nav-link"
           @click.prevent="selectCompany(company)"
           :class="{'active': isCompanySelected(company)}"
           href="#" data-bs-toggle="tab" role="tab">
          {{ company ? company.name : 'No company' }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CompanySelector",
  props: ['companies', 'selectedCompany'],
  computed: {},
  methods: {
    isCompanySelected(company) {
      if (this.selectedCompany) {
        return this.selectedCompany.id === company.id;
      }
      return false;
    },

    selectCompany(company) {
      this.$emit('select-company', company);
    }
  }
}
</script>

<style scoped>

</style>