<template>
  <div>
    <!-- Is Loading -->
    <div v-if="isLoading" class="text-center">
      <spinner></spinner>
    </div>
    <!-- / Is Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading && conversations.length">
      <company-selector :companies="companiesToShow"
                        @select-company="selectCompany"
                        :selected-company="selectedCompany"></company-selector>

      <conversation-selector :conversations="conversationsToShow"
                             @select-conversation="selectConversation"
                             :selected-conversation="selectedConversation"></conversation-selector>

      <tab-selector :current-tab="tab" @select-tab="selectTab"></tab-selector>

      <conversation-messages v-if="selectedConversation && tab === 'messaging'"
                             :job-id="jobId"
                             @navigate-to-quotes="() => $emit('navigate-to-quotes')"
                             :conversation="selectedConversation"
      ></conversation-messages>

      <conversation-files v-if="selectedConversation && tab === 'files'"
                          :job-id="jobId" :conversation="selectedConversation"></conversation-files>
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import conversations from "../../../api/conversations";
import Forms from "../../../mixins/Forms";
import Spinner from "../../../components/Spinner";
import ConversationSelector from "./JobMessaging/ConversationSelector";
import ConversationMessages from "./JobMessaging/ConversationMessages";
import ConversationFiles from "./JobMessaging/ConversationFiles";
import TabSelector from "./JobMessaging/TabSelector";
import CompanySelector from "./JobMessaging/CompanySelector";

const _ = require('lodash');

export default {
  name: "JobMessaging",
  props: ['jobId'],
  mixins: [Forms],
  components: {TabSelector, CompanySelector, ConversationSelector, ConversationMessages, Spinner, ConversationFiles},
  data() {
    return {
      isLoading: false,
      serverErrors: [],
      conversations: [],
      selectedCompany: null,
      selectedConversation: null,
      tab: 'messaging' // messaging, files, activity
    }
  },
  created() {
    this.loadConversations();
  },
  computed: {
    companies() {
      return _.map(this.conversations, 'provider_company');
    },

    companiesToShow() {
      return _.uniqBy(_.without(this.companies, null), 'id');
    },

    conversationsToShow() {
      return _.filter(this.conversations, conversation => {
        if (conversation.provider_company_id) {
          return (this.selectedCompany && this.selectedCompany.id === conversation.provider_company_id);
        }
        return true;
      })
    }
  },
  methods: {
    loadConversations() {
      let vm = this;
      vm.isLoading = true;
      vm.serverErrors = [];
      conversations.loadConversationsForJob(this.jobId).then(r => {
        vm.isLoading = false;
        vm.conversations = r.data;
        if (vm.companiesToShow.length) {
          let c = _.first(vm.companiesToShow);
          vm.selectCompany(c);
        } else if (vm.conversations.length) {
          vm.selectedConversation = _.first(vm.conversations);
        }
      }).catch(e => {
        console.log(e);
        vm.setAndNotifyErrors(e, 'Error loading conversations');
      })
    },

    selectConversation(conversation) {
      this.selectedConversation = conversation;
    },

    selectCompany(company) {
      this.selectedCompany = company;
      this.selectedConversation = _.first(this.conversationsToShow);
    },

    selectTab(tab) {
      this.tab = tab;
    }
  }
}
</script>

<style scoped>

</style>