<template>
  <div class="conversation-selector">
    <div class="conversation-selector-tab"
         :class="{'active': isConversationSelected(conversation), 'disabled': !conversation.enabled}"
         @click.prevent="selectConversation(conversation)"
         v-for="(conversation, i) in conversations" :key="i">

      <div class="flex-wrapper">
        <div class="left">
          <!-- Avatars -->
          <div class="avatars">
            <div class="conversation-member" v-for="member in getMembersToShowForConversation(conversation)">
<!--              <img width="45" :class="{'opacity-40': !isMemberOnline(member)}"-->
<!--                   class="rounded-circle" :src="getAvatarSrc(member.user_role)" alt="Avatar"/>-->
              <user-role-avatar width="45" :class="{'opacity-40': !isMemberOnline(member)}"
                                class="rounded-circle"
                  :user-role="member.user_role"></user-role-avatar>
            </div>
          </div>
          <!-- / Avatars -->
        </div>
        <div class="right">
          <div>
            <b>{{ getConversationName(conversation) }}</b>
          </div>
          <span class="conversation-description">{{ getMemberDescriptions(conversation) }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Images from "../../../../mixins/Images";
import {mapGetters} from 'vuex';
import UserRoleAvatar from "../../../../components/UserRoleAvatar";

const _ = require('lodash');

export default {
  name: "ConversationSelector",
  components: {UserRoleAvatar},
  props: ['conversations', 'selectedConversation'],
  mixins: [Images],
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    })
  },
  methods: {
    isConversationSelected(conversation) {
      if (this.selectedConversation) {
        return this.selectedConversation.id === conversation.id;
      }
      return false;
    },

    selectConversation(conversation) {
      this.$emit('select-conversation', conversation);
    },

    isMemberOnline(member) {
      return member.id;
    },

    getMembersToShowForConversation(conversation) {
      return _.filter(conversation.conversation_members, member => {
        return member.user_role_id !== this.userRole.id;
      })
    },

    getConversationName(conversation) {
      let members = this.getMembersToShowForConversation(conversation);
      if (members.length > 1) {
        return 'Group Chat'
      } else {
        let firstMember = _.first(members);
        if (firstMember.user_role) {
          return firstMember.user_role.name;
        }
        return 'Unknown';
      }
    },

    capitaliseFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getMemberDescriptions(conversation) {
      let descriptions = [];

      let members = this.getMembersToShowForConversation(conversation);
      _.each(members, member => {
        if (member && member.user_role) {
          descriptions.push(
              this.capitaliseFirstLetter(member.user_role.role)
          );
        }
      });

      return descriptions.join(', ');
    }
  }
}
</script>

<style scoped lang="scss">
.conversation-selector {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efecf3;

  .conversation-selector-tab {
    display: flex;
    align-items: center;

    //max-width: 340px;
    flex: 1;
    cursor: pointer;

    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    padding: 10px;

    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border: 1px solid #efecf3;
    border-bottom: none;

    &.active {
      //background: #efecf3;
      background: #f5f4f8;
      //background-color: rgba(93, 60, 242, .1);
      //background-color: rgba(253,86,49,.1);
    }

    .avatars {
      .conversation-member {
        display: inline-block;
        margin-right: 10px;
      }
    }

    .conversation-description {
      font-size: 15px;
    }

    .flex-wrapper {
      display: flex;
      align-items: center;

      .left {
        flex-shrink: 1;
        padding-right: 10px;
      }

      .right {
        flex-grow: 1;
      }
    }
  }
}
</style>