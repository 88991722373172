<template>
  <div class="conversation-messages-wrapper" :class="{'dragging-file': isDraggingFile}" @drop.prevent="addFile"
       @dragover.prevent="isDraggingFile = true" @dragleave.prevent="isDraggingFile = false">
    <!-- Messages -->
    <div class="p-3 conversation-messages" ref="conversationMessages">
      <VueEternalLoading v-model:is-initial="isInitial"
                         :load="loadMessages"
                         position="top"></VueEternalLoading>

      <read-indicator :conversation="conversation"
                      :conversation-members="conversationMembers"
                      :message="null" :newer-message="firstMessage"
      ></read-indicator>
      <div v-for="(message, i) in messagesToShow">
        <message :conversation-members="conversationMembers"
                 @navigate-to-quotes="() => $emit('navigate-to-quotes')"
                 :job-id="jobId" :message="message" :key="i"></message>
        <read-indicator :conversation="conversation"
                        :conversation-members="conversationMembers"
                        :message="message" :newer-message="getNewerMessage(i)"></read-indicator>
      </div>
    </div>
    <!-- / Messages -->

    <!-- Send Message -->
    <div class="send-message-wrapper p-3">
      <div class="flex-wrapper">
        <div class="file-upload">
          <input multiple
                 :key="fileUploadInputKey"
                 @change="handleFilesUploaded"
                 ref="file" type="file" class="file-uploader">
          <button @click.prevent="uploadFile"
                  class="btn btn-icon btn-outline-primary me-2" type="button">
            <i class="fi-upload-file"></i>
          </button>
        </div>
        <div class="left">
          <input placeholder="Enter message here..."
                 :disabled="isSendingMessage"
                 @keydown.enter="sendMessage"
                 type="text" class="form-control" v-model="messageToSend"/>
        </div>
        <div class="right">
          <button
              :class="{'opacity-50': (isSendingMessage||!canSendMessage)}"
              type="button"
              @click.prevent="sendMessage"
              class="btn btn-dark">
            <spinner v-if="isSendingMessage" class="spinner-border-sm me-2"></spinner>
            {{ isSendingMessage ? 'Sending' : 'Send' }}
          </button>
        </div>
      </div>

      <!-- Files -->
      <div class="files mt-3" v-if="filesToSend.length">
        <div class="badge bg-primary file py-2 px-3" v-for="file in filesToSend">
          {{ file.name }}
          <button @click.prevent="removeFile(file)"
                  type="button" class="btn btn-xxs btn-link text-white btn-icon w-auto py-1 ms-2">
            <i class="fi-x"></i>
          </button>
        </div>
      </div>
      <!-- / Files -->
    </div>
    <!-- / Send Message -->
  </div>
</template>

<script>
import conversations from "../../../../api/conversations";
import {useToast} from 'vue-toastification';
import {VueEternalLoading} from '@ts-pro/vue-eternal-loading';
import Spinner from "../../../../components/Spinner";
import Message from "./ConversationMessages/Message";
import {mapGetters} from 'vuex';
import ReadIndicator from "./ConversationMessages/ReadIndicator";

const toast = useToast();

const PAGE_SIZE = 1000;

export default {
  name: "ConversationMessages",
  props: ['jobId', 'conversation'],
  data() {
    return {
      page: 1,
      messages: [],

      messageToSend: '',
      filesToSend: [],
      fileUploadInputKey: 1,
      isSendingMessage: false,
      isInitial: false,

      isMarkingAsRead: false,

      conversationMembers: [],

      isDraggingFile: false
    }
  },
  created() {
    let vm = this;
    vm.conversationMembers = _.cloneDeep(this.conversation.conversation_members);
    window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('MessageSent', (e) => {
      vm.handleMessageReceived(e.message);
    });
    window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('UserReadConversation', (e) => {
      vm.handleUserReadConversation(e.conversationMember);
    });
  },
  watch: {
    conversation() {
      this.page = 1;
      this.messages = [];
      this.isInitial = true;
      let vm = this;
      vm.conversationMembers = _.cloneDeep(this.conversation.conversation_members);
      window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('MessageSent', (e) => {
        vm.handleMessageReceived(e.message);
      });
      window.Echo.private(`conversations.${this.conversation.id}.${this.userRole.id}`).listen('UserReadConversation', (e) => {
        vm.handleUserReadConversation(e.conversationMember);
      });
    }
  },
  components: {ReadIndicator, Message, VueEternalLoading, Spinner},
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),

    firstMessage() {
      return _.first(this.messages);
    },

    messagesToShow() {
      return _.orderBy(this.messages, 'created_at')
    },

    canSendMessage() {
      return this.messageToSend
    }
  },
  methods: {
    getNewerMessage(laterMessageIndex) {
      if (this.messagesToShow.length === laterMessageIndex + 1) {
        return null;
      }
      return this.messagesToShow[Number(laterMessageIndex) + 1];
    },

    uploadFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },

    handleFilesUploaded(e) {
      let files = e.target.files;
      ([...files]).forEach(f => {
        this.filesToSend.push(f);
      });
    },

    addFile(e) {
      this.isDraggingFile = false;
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      ([...droppedFiles]).forEach(f => {
        this.filesToSend.push(f);
      });
    },

    removeFile(file) {
      this.filesToSend = this.filesToSend.filter(f => {
        return f !== file;
      });
    },

    loadMessages({loaded, noMore}) {
      let vm = this;
      conversations.searchMessages(
          this.jobId,
          this.conversation.id,
          this.page,
          PAGE_SIZE
      ).then(({data}) => {
        vm.messages.unshift(...data.data.reverse());

        if (data.data.length == 0) {
          noMore()
        } else {
          loaded(this.messages.length, PAGE_SIZE);
        }

        if (this.page === 1) {
          vm.scrollMessagesToBottom();
          vm.markAsRead();
        }
        vm.page += 1;
      }).catch(e => {
        console.log(e);
        // toast.error('Error loading messages');
      })
    },

    sendMessage() {
      if (this.isSendingMessage || !this.canSendMessage) {
        return false;
      }
      let vm = this;
      vm.isSendingMessage = true;
      conversations.sendMessage(
          this.jobId,
          this.conversation.id,
          vm.messageToSend,
          vm.filesToSend
      ).then(() => {
        vm.isSendingMessage = false;
        vm.messageToSend = '';
        vm.fileUploadInputKey += 1;
        vm.filesToSend = [];
      }).catch(e => {
        console.log(e);
        vm.isSendingMessage = false;
        toast.error('Error sending message');
      });
    },

    markAsRead() {
      this.isMarkingAsRead = true;
      let vm = this;
      conversations.markAsRead(
          this.jobId,
          this.conversation.id
      ).then(r => {
        vm.isMarkingAsRead = false;
      }).catch(e => {
        console.log(e);
        vm.isMarkingAsRead = false;
        toast.error('Error marking as read');
      });
    },

    scrollMessagesToBottom() {
      let vm = this;
      window.setTimeout(function () {
        let elem = vm.$refs['conversationMessages'];
        elem.scrollTop = elem.scrollHeight;
      }, 100)
    },

    handleMessageReceived(message) {
      if (message.conversation_id === this.conversation.id) {
        this.messages.push(message);
        this.scrollMessagesToBottom();

        if (message.sent_by_user_role_id !== this.userRole.id) {
          this.markAsRead();
        }
      }
    },

    handleUserReadConversation(conversationMember) {
      if (conversationMember.conversation_id === this.conversation.id) {
        this.conversationMembers = _.map(this.conversationMembers, member => {
          if (member.id === conversationMember.id) {
            return {
              ...member,
              ...conversationMember
            }
          }
          return member;
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.conversation-messages-wrapper {
  position: relative;

  &.dragging-file {
    background: #efecf3;
    &:before {
      content: "";  // :before and :after both require content
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; // Push the element 50% of the container's width to the right
      background-image: url('/img/file-upload-icon.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30%;
    }
    .conversation-messages {
      opacity: 0;
    }
    .send-message-wrapper {
      opacity: 0;
    }
  }
}

.conversation-messages {
  border: 1px solid #efecf3;
  border-top: none;
  overflow-y: scroll;
  min-height: 400px;
  max-height: 400px;
  background: #fff;
  position: relative;

  // Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.send-message-wrapper {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 1px solid #efecf3;
  border-top: none;
  background: #f9f9f9;

  .flex-wrapper {
    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
      padding-left: 10px;
    }
  }
}

.files {
  .file {
    font-size: 14px;
    margin-right: 8px;
  }
}

.file-uploader {
  display: none;
}

.badge {
  overflow-wrap: break-word;
  text-align: left;
}
</style>